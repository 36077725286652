import { UUidHelper } from './UtilsService'
import type { Logger } from '@/store/services/Logger'
import type { FiftyApiClientManager } from '@/store/services/apiClient/FiftyApiClientManager'
import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { Store } from 'vuex'
import { GETTER_USER } from '@/store/modules/Login'
import { FiftyEnvType } from '@/store/FiftyTypes'
import LoginManager from '@/store/services/LoginManager'
import type { Dictionary } from '@/augmented-types'
import type { Router } from 'vue-router'
import Analytics, { type AnalyticsInstance } from 'analytics'
import segmentPlugin from '@analytics/segment'

export enum TrackEventType {
  hasOpenedModal = 1,
  hasClosedModal = 2,
  clickAcceptChallenge = 5,
  clickValidateChallenge = 6,
  clickChooseChallenge = 7,
  clickRescheduleChallenge = 8,
  clickUseReminderCheckbox = 15,
  clickUseCalendarInvite = 17,
  switchSubTabName = 19,
  switchPageFromMenu = 20,
  redoRetryChallengeClicked = 22,
  datePickerOptionChosen = 28,
  dashboardKpiFilterChosen = 30,
  clickToCloseChallenge = 31,
  clickToOpenModalCreateCustomChallengeAfterOB = 34,
  customChallengeCreatedAfterOB = 35,
  customChallengeUpdatedAfterOB = 36,
  clickOnRemoveCustomChallengeIconAfterOB = 37,
  confirmRemoveCustomChallengeAfterOB = 38,
  clickOnUpdateCustomChallengeIconAfterOB = 39,
  clickCancelIssueChallengeButtonInTooltip = 43,
  clickOnCancelCourse = 46,
  clickOnChangeCourse = 47,
  clickGetContactMailForHelp = 50,
  clickMailToLinkForHelp = 51,
  clickOnBoardingBackLink = 52,
  clickAddUserToCampaign = 60,
  clickBatchUploadButton = 63,
  clickShortcutIntentionDay = 64,
  clickPickIntentionDay = 65,
  changeReminderTime = 67,
  changeCalendarReminderTime = 69,
  changeReminderChannelType = 70,
  clickRefreshTeamsInstallationStatus = 71,
  clickRefreshMobileAppInstallationStatus = 72,
  clickSendRecommendChallengeButtonInTooltip = 74,
  clickOpenPopup = 75,
  clickClosePopup = 76,
  clickScheduleChallenge = 77,
  selectTeamForChallenge = 78,
  clickCreateUserTeam = 79,
  clickTryCreateHabitFromValidation = 81,
  clickTryCreateHabitFromCard = 82,
  clickConfirmCreateHabitFromValidation = 83,
  clickConfirmCreateHabitFromCard = 84,
  clickCancelCreateHabitFromValidation = 85,
  clickCancelCreateHabitFromCard = 86,
  clickValidateHabitChallenge = 87,
  clickAcquiredHabit = 88,
  clickTryStopHabit = 89,
  clickStopHabit = 90,
  clickCancelStopHabit = 91,
  clickTryModifyHabit = 92,
  clickModifyHabit = 93,
  clickCancelModifyHabit = 94,
  clickResumeHabit = 96,
  clickCancelResumeHabit = 97,
  clickContactUsOnExpiredLicensePage = 98,
  mountedExpiredLicensePage = 99,
  clickSeeChallenges = 100,
  clickChangePersonalizationFromChoicePage = 101,
  clickSavePersonalizationModal = 102,
  clickCancelPersonalizationModal = 103,
  closedPersonalizationModal = 104,
  clickRequestMagicLink = 105,
  obtainNewBadge = 108,
  newCourseCheckedProfile = 110,
  newCourseUncheckedProfile = 111,
  newCourseCheckedSkill = 112,
  newCourseUncheckedSkill = 113,
  newCourseDenyCandidate = 119,
  newCourseDenyWithReasonCandidate = 120,
  newCourseConfirmCandidate = 122,
  exportHtmlToImageSuccess = 124,
  exportHtmlToImageFail = 125,
  npsDisplayed = 126,
  ssoUserTriedLogin = 127,
  ssoUserAlreadyLoggedIn = 128,
  externalProvidersOpenModal = 129,
  externalProvidersCloseModal = 130,
  externalProvidersSelectEvent = 131,
  externalProvidersActivateEvent = 132,
  externalProvidersDeactivateEvent = 133,
  externalProvidersAddCriterion = 134,
  externalProvidersRemoveCriterion = 135,
  externalProvidersUnavailableOpenModal = 136,
  externalProvidersUnavailableCloseModal = 137,
  clickStartDeleteCustomAction = 139,
  clickCancelDeleteCustomAction = 140,
  clickOpenCreateCustomActionModal = 141,
  clickOpenUpdateCustomActionModal = 142,
  changeCustomChallengeModalLanguageTab = 143,

  // Admin home
  clickAdminProgram = 144,
  clickAdminOpenCreateProgramPopup = 145,
  clickAdminCancelCreateProgramPopup = 146,
  clickAdminSaveCreateProgramPopup = 147,
  clickAdminQuickAction = 148,
  clickAdminCreateAdmin = 149,

  // Admin Program
  clickAdminProgramTab = 150,
  clickAdminProgramOpenSettingsModal = 151,
  clickAdminProgramSaveSettingsModal = 152,
  clickAdminProgramCommunicationOnboardingPreviz = 153,
  clickAdminProgramCommunicationEmailPreviz = 154,
  clickAdminProgramChangeCommunicationLangTab = 155,
  clickAdminProgramCampaignDetails = 157,
  oldSharedAccessLinkUsed = 158,
  clickAdminExpiredContractContactFifty = 159,

  // Admin ActionRepository
  actionRepositoryPageVisit = 160,
  actionRepositoryMoreDetailsModal = 161,
  actionRepositoryOpenProgramSelectModal = 162,
  actionRepositoryCloseProgramSelectModal = 163,
  actionRepositoryOpenProgramSelectModalPillarsStep = 164,
  actionRepositoryActionsLinked = 166,
  clickAddUsersToCampaign = 167,

  backofficeBoosterModalVisit = 170,
  backofficeBoosterConfirm = 171,

  collapseBlock = 180,

  // Admin Program - Communication
  clickAdminProgramCommunicationDownloadFile = 181,
  clickAdminProgramCommunicationDeleteContext = 182,
  clickAdminProgramCommunicationDeleteContextCancel = 183,
  clickAdminProgramCommunicationDeleteContextConfirm = 184,
  toggleAdminProgramCommunicationContextItem = 185,
  clickGoToAdminProgramCommunicationExperience = 186,
  clickAdminProgramCommunicationContextAdded = 187,
  clickGoToAdminProgramCommunicationContext = 188,

  clickAdminDownloadCampaignParticipants = 189,
  clickAdminOpenDeleteAllParticipantsModal = 190,
  clickAdminCancelDeleteAllParticipantsModal = 191,
  clickAdminConfirmDeleteAllParticipantsModal = 192,

  // Admin Program - Actions
  clickAdminProgramActionHowToTreat = 193,
  clickAdminProgramActionRecommandations = 194,

  // Admin Program - Actions review List
  clickAdminProgramActionReviewListAction = 195,
  clickAdminProgramActionReviewListActionTypePillar = 196,

  // Component FiftyPillarGraph
  clickFiftyPillarGraphPillarTab = 197,
  clickOpenAskActionModal = 198,
  clickFiftyPillarGraphRemovePillar = 199,

  // Admin Program - Information
  clickAdminProgramInformationManagerProfileCheckbox = 200,
  clickAdminProgramInformationAboutProgram = 201,
  clickAdminProgramInformationStructureProgram = 202,
  clickAdminProgramInformationAddPillar = 203,
  clickAdminProgramInformationSaveAndQuit = 204,
  clickAdminProgramInformationGenerateActions = 205,

  clickGoToChallengeTranslationHistory = 206,
  clickGoBackToChallengeEdition = 207,
  clickRestoreChallengeTranslationHistory = 208,
  clickrestoreChallengeTranslationInitialState = 209,
  clickAdminProgramCampaignTabsCurrent = 210,
  clickAdminProgramCampaignTabsPast = 211,
  clickAdminProgramCampaignTabsFuture = 212,

  // Admin Program  - Segment
  clickAdminProgramSegmentsSave = 213,

  // Admin KPI - Program KPI
  clickAdminCompareGroupTab = 214,

  // Admin KPI - Compare Segment
  adminCompareSegmentProgramClick = 215,
  adminCompareSegmentProgramSelect = 216,
  adminCompareSegmentClick = 217,
  adminCompareSegmentSelect = 218,
  adminCompareSegmentDownloadClick = 219,
  adminCompareSegmentDatagridSortColumnChange = 220,

  clickFiftyPillarGraphAddPillar = 221,
  clickAdminProgramInformationSaveAndQuitConfirmCancel = 222,
  clickAdminProgramInformationSaveAndQuitConfirmSave = 223,

  clickAdminDownloadProgramParticipants = 224,
  clickAdminDownloadClientParticipants = 225,

  clickAdminProgramEditActionModal = 226,
  clickAdminProgramEditActionModalConfirm = 227,
  clickAdminProgramEditActionModalCancel = 228,

  clickAdminProgramShowHistory = 229,
  clickAdminProgramHideHistory = 230,
  clickAdminProgramPreviewHistory = 221,
  clickAdminProgramCurrentVersion = 232,
  clickAdminProgramRestoreHistory = 233,

  // Admin KPIs
  adminKpiOpenFilters = 234,
  adminKpiSelectFilter = 235,
  adminKpiResetFilters = 236,
  adminKpiRemoveFilter = 237,
  adminKpiHomeChangeTab = 238,

  // adminKpiHomeProgram
  adminKpiHomeProgramTabProgramChosen = 239,

  // adminKpiHomeCrossProgram
  adminKpiHomeCrossProgramTabProgramChosen = 240,

  // adminKpiPractice
  adminKpiPracticeGoBackToKpiHome = 250,

  // adminKpiPillars
  adminKpiPillarsGoBackToKpiHome = 260,
  adminKpiPillarsSelectPillar = 265,

  // adminKpiAdoption
  adminKpiAdoptionGoBackToKpiHome = 270,

  clickAdminProgramActionReviewToggleRejectReasons = 280,
  clickAdminProgramActionReviewConfirmRejectAction = 281,
  clickAdminProgramActionReviewConfirmValidateAction = 282,
  clickAdminProgramActionReviewAddComment = 283,

  clickAdminProgramInformationGoToActions = 284,

  clickAdminProgramCommunicationContextRemoved = 285,

  adminKpiPracticeGoBackToProgramKpiPage = 286,
  adminKpiPillarsGoBackToProgramKpiPage = 287,
  adminKpiAdoptionGoBackToProgramKpiPage = 288,

  clickAdminArchivedProgramsGoBackToPrograms = 289,

  onboardingClickNext = 290,
  clickChangeActionTranslationTab = 291,

  clickAdminProgramActionsOpenCreatePillarModal = 292,
  clickAdminProgramActionsCreatePillarConfirmed = 293,
  clickAdminProgramActionsOpenEditPillarModal = 294,
  clickAdminProgramActionsEditPillarConfirmed = 295,
  clickAdminProgramActionsDeletePillar = 296,
  clickAdminProgramActionsDroppedActionToPillar = 297,

  clickAdminCreateProgramByFile = 298,
  clickAdminCreateProgramByHand = 299,

  // Admin Program Options
  clickAdminProgramOptionsOpen = 300,
  clickAdminProgramOptionsDuplicate = 301,
  clickAdminProgramOptionsArchive = 302,
  clickAdminProgramOptionsUnarchive = 303,
  clickAdminProgramOptionsDelete = 304,
  clickAdminProgramOptionsCancelDelete = 305,
  clickAdminProgramOptionsConfirmDelete = 306,
  clickAdminProgramOpenOptionsDropdownOnProgramHeader = 307,
  clickAdminProgramOpenOptionsDropdownOnProgramCard = 308,

  clickAdminUserPageChangeTab = 310,
  clickAdminUserPageActivityOpenProgram = 311,
  clickAdminUserPageParamsChange = 312,

  // Search Actions
  clickAdminChallengeReviewCreateActionsAutomatically = 313,
  clickAdminChallengeReviewCreateActionsManually = 314,
  clickAdminChallengeReviewAddActionToPillarManually = 315,

  // Admin create action with AI
  clickAdminCreateActionWithAIHelpLink = 316,
  AdminCreateActionWithAIConfirmInputWithEnterKey = 317,
  AdminCreateActionWithAIConfirmInputWithBtnClick = 318,
  adminCreateActionWithAIUseAnotherWordingClick = 319,
  adminCreateActionWithAIConfirmWordingStep = 320,
  adminCreateActionWithAIAddOtherActionToProgramClick = 321,
  adminCreateActionWithAIConfirmSimilarityStep = 322,
  adminCreateActionWithAIGenerateContentClick = 323,
  adminCreateActionWithAIGenerateContentAgainClick = 324,
  adminCreateActionWithAIConfirmCreateActionClick = 325,
  adminCreateActionWithAIWithErrorRetryClick = 326,
  adminCreateActionWithAIErrorGoToContentStepClick = 327,

  clickBtn = 328,
  pageView = 329,

  clickAdminCreateProgramFromFileHelpLink = 330,

  onActionGroupDetailsBackClick = 331,
  onGroupDetailsBackClick = 332,
  onActionGroupReminderModalGroupsLinkClick = 333,
}

export enum ClickLocation {
  clickedOnCard = 0,
  clickedOnButton = 1,
}

export class SegmentHelper {
  private apiClient: FiftyApiClientManager
  private logger: Logger
  private router: Router
  private store: Store<any>
  private analytics: AnalyticsInstance

  constructor(apiClient: FiftyApiClientManager, logger: Logger, router: Router, store: Store<any>) {
    this.apiClient = apiClient
    this.logger = logger
    this.router = router
    this.store = store

    this.analytics = Analytics({
      app: 'fifty',
      plugins: [
        segmentPlugin({
          writeKey: this.getToken(),
        }),
      ],
    })
  }

  private getToken(): string {
    switch (window.env.NODE_ENV_TYPE) {
      case FiftyEnvType.PreProduction:
        return 'nKC2mLL6MRpUwbzhr6upuVBClrjUMvBV'

      case FiftyEnvType.Production:
        return 'hrh9mUWJjmw2rFrbpca6fbJwGa7JrzC1'

      case FiftyEnvType.Development:
      default:
        return 'YbnjMGm8tUHm8xIIL8pLODL56ZIvG16H'
    }
  }

  public identify(userId: string) {
    try {
      if (!this.isUserIdValid(userId)) return

      this.frontIdentify()
      this.apiClient.analyticsApiClient.identifyUser()
    } catch (e) {
      // Ignore
    }
  }

  private frontIdentify() {
    const userId = LoginManager.getUserId()
    if (!userId) return
    if (!this.isUserIdValid(userId)) return

    const user: FiftyApiClients.UserDto = this.store.getters[GETTER_USER]
    if (!user) {
      this.analytics.identify(userId)
      return
    }

    this.analytics.identify(userId, {
      language: FiftyApiClients.SupportedLanguageDto[user.language].toLowerCase(),
      hasMobileAppInstalled: user.hasMobileAppInstalled,
      role: FiftyApiClients.FiftyRoleDto[user!.userRights!.adminRole].toLowerCase(),
      clientName: user.client?.name,
    })
  }

  public pageHasLoaded(pageName?: string | null | undefined, properties?: Dictionary<any> | null) {
    try {
      this.analytics.page()
      this.frontIdentify()
    } catch (e) {
      console.error(e)
    }
  }

  public track(eventName: TrackEventType, properties?: Dictionary<any> | null) {
    try {
      let prop = this.setIds(properties)
      prop = this.addCommonProperties(prop)

      const nameOfEventType = TrackEventType[eventName]
      this.analytics.track(nameOfEventType, prop)

      const segmentRequest = { name: nameOfEventType, properties: prop, time: null } as FiftyApiClients.SegmentRequest
      this.apiClient.analyticsApiClient.track(segmentRequest)
    } catch (e) {
      // Ignore
    }
  }

  private addCommonProperties(properties?: Dictionary<any> | null): Dictionary<any> {
    const commonProperties: any = { ...properties }

    commonProperties.pageName = this.router.currentRoute.value.name

    return commonProperties
  }

  private setIds(properties?: Dictionary<any> | null): Dictionary<any> {
    const analyticsProperties: any = { ...properties }

    analyticsProperties.userId = LoginManager.getUserId()
    analyticsProperties.anonymousId = LoginManager.getSegmentAnonymousId()

    if (
      !analyticsProperties.anonymousId &&
      typeof this.analytics?.user === 'function' &&
      typeof this.analytics.user()?.anonymousId === 'function'
    ) {
      analyticsProperties.anonymousId = this.analytics.user().anonymousId() // Will set it if not already the case
    }

    return analyticsProperties
  }

  private isUserIdValid(userId: string): boolean {
    if (userId === null) {
      this.logger.logInformation(`userid is null`)
      return false
    }

    if (userId === undefined) {
      this.logger.logInformation(`userid is undefined`)
      return false
    }

    if (!UUidHelper.isUuid(userId)) {
      this.logger.logError(`'${userId}' is not in the right uuid format`)
      return false
    }

    return true
  }
}
