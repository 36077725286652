/* eslint-disable @typescript-eslint/no-namespace */
import { FiftyEnvType } from '@/store/FiftyTypes'

export namespace FiftyEnv {
  export interface IFiftyEnvData {
    NODE_ENV: string
    NODE_ENV_TYPE: FiftyEnvType
    API_URL: string // this is the web API url, we don't access the actual back-end api from here...
    PUBLIC_BASE_URL: string
    IMG_URL: string
    SEGMENT_IS_ENABLED: boolean
  }

  export class EnvHelper {
    /**
     * So to have runtime env variables, we have to do this ugly trick.
     * vue basic env variable usage is compile time env variable which is annoying as you have to compile specifically for each environment
     * (which means we can't test a build in preprod then push it to prod as it won't have the right variables...)
     * This solves the issue. Those variables are STORED in window.env ! No need to pull them each time.
     */
    public static getEnvironmentVariables(window: any): IFiftyEnvData {
      const hostname = window && window.location && window.location.hostname

      const localHostName = EnvHelper.getLocalString(hostname)
      // TODO change this to a proper env variable
      if (localHostName) {
        return {
          NODE_ENV: 'development',
          NODE_ENV_TYPE: FiftyEnvType.Development,
          API_URL: `http://${localHostName}:5000`,
          PUBLIC_BASE_URL: 'https://fifty.do', // always the same as the public website only exists in prod
          IMG_URL: `https://img.fifty.do/d`,
          SEGMENT_IS_ENABLED: false,
        } as IFiftyEnvData
      }

      // Used for local teams dev
      if (hostname.includes('teams-fifty-dev.ngrok.dev')) {
        return {
          NODE_ENV: 'development',
          NODE_ENV_TYPE: FiftyEnvType.Development,
          API_URL: 'https://api.teams-fifty-dev.ngrok.dev',
          PUBLIC_BASE_URL: 'https://fifty.do', // always the same as the public website only exists in prod
          IMG_URL: `https://img.fifty.do/d`,
          SEGMENT_IS_ENABLED: false,
        } as IFiftyEnvData
      }

      if (hostname.includes('preprod')) {
        return {
          NODE_ENV: 'preproduction',
          NODE_ENV_TYPE: FiftyEnvType.PreProduction,
          API_URL: 'https://api-preprod.app.fifty.do/api',
          PUBLIC_BASE_URL: 'https://fifty.do', // always the same as the public website only exists in prod
          IMG_URL: `https://img.fifty.do/pp`,
          SEGMENT_IS_ENABLED: false,
        } as IFiftyEnvData
      }
      return {
        NODE_ENV: 'production',
        NODE_ENV_TYPE: FiftyEnvType.Production,
        API_URL: 'https://app.fifty.do/api',
        PUBLIC_BASE_URL: 'https://fifty.do', // always the same as the public website only exists in prod
        IMG_URL: `https://img.fifty.do/p`,
        SEGMENT_IS_ENABLED: true,
      } as IFiftyEnvData
    }

    private static getLocalString(hostname: string): string | null {
      if (hostname.includes('10.0.2.2')) {
        return '10.0.2.2'
      }

      if (hostname.includes('localhost')) {
        return 'localhost'
      }

      if (hostname.includes('127.0.0.1')) {
        return '127.0.0.1'
      }

      if (hostname.includes('bs-local.com')) {
        // to debug from browserstack
        return 'bs-local.com'
      }

      if (hostname.includes('eu-west-3.compute.amazonaws.com')) {
        return hostname
      }

      return null
    }
  }
}
