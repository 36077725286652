import { FiftyApiClients } from './generated/fiftyApiClient'
import { CustomApiClientCode } from './CustomApiClientCode'
import type { Store } from 'vuex'
import type { Router } from 'vue-router'

export class FiftyApiClientManager {
  public accountApiClient!: FiftyApiClients.AccountApiClient
  public challengeApiClient!: FiftyApiClients.ChallengeApiClient
  public sessionApiClient!: FiftyApiClients.SessionApiClient
  public loggerApiClient!: FiftyApiClients.LoggerApiClient
  public contentApiClient!: FiftyApiClients.ContentApiClient
  public analyticsApiClient!: FiftyApiClients.AnalyticsApiClient
  public statusApiClient!: FiftyApiClients.StatusApiClient
  public progressApiClient!: FiftyApiClients.ProgressApiClient
  public userFeedbackApiClient!: FiftyApiClients.UserFeedbackApiClient
  public mainUserOnBoardingApiClient!: FiftyApiClients.MainUserOnBoardingApiClient
  public customChallengeApiClient!: FiftyApiClients.CustomChallengeApiClient
  public recommendedChallengeApiClient!: FiftyApiClients.RecommendedChallengeApiClient
  public groupApiClient!: FiftyApiClients.GroupApiClient
  public groupActionApiClient!: FiftyApiClients.GroupActionApiClient
  public habitApiClient!: FiftyApiClients.HabitApiClient

  // admin
  public adminClientApiClient!: FiftyApiClients.AdminClientApiClient

  // backoffice
  public backOfficeClientExternalProviderApiClient!: FiftyApiClients.BackOfficeClientExternalProviderApiClient
  public backOfficeGeneralSearchApiClient!: FiftyApiClients.BackOfficeGeneralSearchApiClient
  public backOfficeClientApiClient!: FiftyApiClients.BackOfficeClientApiClient
  public backOfficeEnterpriseApiClient!: FiftyApiClients.BackOfficeEnterpriseApiClient

  // Client
  public clientSegmentApiClient!: FiftyApiClients.ClientSegmentApiClient
  public clientFileApiClient!: FiftyApiClients.ClientFileApiClient

  // Programs
  public kpiApiClient!: FiftyApiClients.KpiApiClient
  public campaignApiClient!: FiftyApiClients.CampaignApiClient
  public campaignExternalApiClient!: FiftyApiClients.CampaignExternalApiClient
  public programActionsCandidateApiClient!: FiftyApiClients.ProgramActionsCandidateApiClient
  public programActionsApiClient!: FiftyApiClients.ProgramActionsApiClient
  public programActionsReviewApiClient!: FiftyApiClients.ProgramActionsReviewApiClient
  public programContextApiClient!: FiftyApiClients.ProgramContextApiClient
  public programApiClient!: FiftyApiClients.ProgramApiClient
  public programOnboardingApiClient!: FiftyApiClients.ProgramOnboardingApiClient
  public programSegmentApiClient!: FiftyApiClients.ProgramSegmentApiClient
  public pillarApiClient!: FiftyApiClients.PillarApiClient
  public programFileApiClient!: FiftyApiClients.ProgramFileApiClient

  // Users
  public userApiClient!: FiftyApiClients.UserApiClient
  public userPropertiesApiClient!: FiftyApiClients.UserPropertiesApiClient
  public userHistoryApiClient!: FiftyApiClients.UserHistoryApiClient
  public userImportExportApiClient!: FiftyApiClients.UserImportExportApiClient
  public userCampaignApiClient!: FiftyApiClients.UserCampaignApiClient
  public userSearchApiClient!: FiftyApiClients.UserSearchApiClient
  public messageApiClient!: FiftyApiClients.MessageApiClient
  public messagingApiClient!: FiftyApiClients.MessagingApiClient

  // Actions
  public filesApiClient!: FiftyApiClients.FilesApiClient
  public skillListerApiClient!: FiftyApiClients.SkillListerApiClient
  public baseActionsApiClient!: FiftyApiClients.BaseActionsApiClient
  public actionsApiClient!: FiftyApiClients.ActionsApiClient
  public actionsOverwriteApiClient!: FiftyApiClients.ActionsOverwriteApiClient
  public actionsTagApiClient!: FiftyApiClients.ActionsTagApiClient
  public llmActionGenerationTopicsApiClient!: FiftyApiClients.LlmActionGenerationTopicsApiClient

  init(config: CustomApiClientCode.BaseFiftyApiClientDependencies) {
    this.accountApiClient = new FiftyApiClients.AccountApiClient(config)
    this.challengeApiClient = new FiftyApiClients.ChallengeApiClient(config)
    this.sessionApiClient = new FiftyApiClients.SessionApiClient(config)
    this.loggerApiClient = new FiftyApiClients.LoggerApiClient(config)
    this.contentApiClient = new FiftyApiClients.ContentApiClient(config)
    this.analyticsApiClient = new FiftyApiClients.AnalyticsApiClient(config)
    this.statusApiClient = new FiftyApiClients.StatusApiClient(config)
    this.progressApiClient = new FiftyApiClients.ProgressApiClient(config)
    this.userFeedbackApiClient = new FiftyApiClients.UserFeedbackApiClient(config)
    this.mainUserOnBoardingApiClient = new FiftyApiClients.MainUserOnBoardingApiClient(config)
    this.customChallengeApiClient = new FiftyApiClients.CustomChallengeApiClient(config)
    this.recommendedChallengeApiClient = new FiftyApiClients.RecommendedChallengeApiClient(config)
    this.groupApiClient = new FiftyApiClients.GroupApiClient(config)
    this.groupActionApiClient = new FiftyApiClients.GroupActionApiClient(config)
    this.habitApiClient = new FiftyApiClients.HabitApiClient(config)

    // admin
    this.adminClientApiClient = new FiftyApiClients.AdminClientApiClient(config)

    // backoffice
    this.backOfficeClientApiClient = new FiftyApiClients.BackOfficeClientApiClient(config)
    this.backOfficeEnterpriseApiClient = new FiftyApiClients.BackOfficeEnterpriseApiClient(config)
    this.backOfficeClientExternalProviderApiClient = new FiftyApiClients.BackOfficeClientExternalProviderApiClient(
      config,
    )
    this.backOfficeGeneralSearchApiClient = new FiftyApiClients.BackOfficeGeneralSearchApiClient(config)

    // Client
    this.clientSegmentApiClient = new FiftyApiClients.ClientSegmentApiClient(config)
    this.clientFileApiClient = new FiftyApiClients.ClientFileApiClient(config)

    // Programs
    this.kpiApiClient = new FiftyApiClients.KpiApiClient(config)
    this.campaignApiClient = new FiftyApiClients.CampaignApiClient(config)
    this.campaignExternalApiClient = new FiftyApiClients.CampaignExternalApiClient(config)
    this.programActionsCandidateApiClient = new FiftyApiClients.ProgramActionsCandidateApiClient(config)
    this.programActionsApiClient = new FiftyApiClients.ProgramActionsApiClient(config)
    this.programActionsReviewApiClient = new FiftyApiClients.ProgramActionsReviewApiClient(config)
    this.programContextApiClient = new FiftyApiClients.ProgramContextApiClient(config)
    this.programApiClient = new FiftyApiClients.ProgramApiClient(config)
    this.programOnboardingApiClient = new FiftyApiClients.ProgramOnboardingApiClient(config)
    this.programSegmentApiClient = new FiftyApiClients.ProgramSegmentApiClient(config)
    this.pillarApiClient = new FiftyApiClients.PillarApiClient(config)
    this.programFileApiClient = new FiftyApiClients.ProgramFileApiClient(config)

    // Users
    this.userApiClient = new FiftyApiClients.UserApiClient(config)
    this.userPropertiesApiClient = new FiftyApiClients.UserPropertiesApiClient(config)
    this.userHistoryApiClient = new FiftyApiClients.UserHistoryApiClient(config)
    this.userImportExportApiClient = new FiftyApiClients.UserImportExportApiClient(config)
    this.userCampaignApiClient = new FiftyApiClients.UserCampaignApiClient(config)
    this.userSearchApiClient = new FiftyApiClients.UserSearchApiClient(config)
    this.messageApiClient = new FiftyApiClients.MessageApiClient(config)
    this.messagingApiClient = new FiftyApiClients.MessagingApiClient(config)

    // Actions
    this.skillListerApiClient = new FiftyApiClients.SkillListerApiClient(config)
    this.filesApiClient = new FiftyApiClients.FilesApiClient(config)
    this.baseActionsApiClient = new FiftyApiClients.BaseActionsApiClient(config)
    this.actionsApiClient = new FiftyApiClients.ActionsApiClient(config)
    this.actionsOverwriteApiClient = new FiftyApiClients.ActionsOverwriteApiClient(config)
    this.actionsTagApiClient = new FiftyApiClients.ActionsTagApiClient(config)
    this.llmActionGenerationTopicsApiClient = new FiftyApiClients.LlmActionGenerationTopicsApiClient(config)
  }

  public static create(
    config: CustomApiClientCode.IAppConfig,
    store: Store<any>,
    router: Router,
  ): FiftyApiClientManager {
    const apiClients = new FiftyApiClientManager()
    const apiClientDependencies = new CustomApiClientCode.BaseFiftyApiClientDependencies(
      config,
      store,
      router,
      apiClients,
    )
    apiClients.init(apiClientDependencies)

    return apiClients
  }
}
