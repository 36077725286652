import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome,
  faTrophy,
  faList,
  faChartLine,
  faCog,
  faInfo,
  faUsers,
  faChevronDown,
  faExternalLink,
  faExclamation,
  faCheck,
  faUser,
  faUserCog,
  faLock,
  faPen,
  faQuestionCircle,
  faChartColumn,
  faDownload,
  faArrowRight,
  faArrowUpRightFromSquare,
  faCircleCheck,
  faTrash,
  faEllipsis,
  faEyeSlash,
  faCalendar,
  faFileMagnifyingGlass,
  faFileCircleInfo,
  faFileCircleXmark,
  faTriangleExclamation,
  faPuzzle,
  faOctagonCheck,
  faCircleInfo,
  faXmark,
  faGears,
  faMagnifyingGlassPlus,
  faFilePen,
  faSwatchbook,
  faChevronUp,
  faMemo,
  faMegaphone,
  faChartSimple,
  faBookCopy,
  faSolarSystem,
  faGear,
  faFile,
  faHourglassStart,
  faSliders,
  faFileCircleCheck,
  faPlusCircle,
  faUpRightFromSquare,
  faUserGroup,
  faArrowLeft,
  faTimes,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faHome,
  faTrophy,
  faList,
  faChartLine,
  faCog,
  faInfo,
  faUsers,
  faChevronDown,
  faExternalLink,
  faExclamation,
  faCheck,
  faPen,
  faUser,
  faUserCog,
  faLock,
  faQuestionCircle,
  faChartColumn,
  faDownload,
  faArrowRight,
  faArrowUpRightFromSquare,
  faCircleCheck,
  faTrash,
  faEllipsis,
  faEyeSlash,
  faCalendar,
  faFileMagnifyingGlass,
  faFileCircleInfo,
  faFileCircleXmark,
  faTriangleExclamation,
  faPuzzle,
  faOctagonCheck,
  faCircleInfo,
  faGears,
  faMagnifyingGlassPlus,
  faFilePen,
  faSwatchbook,
  faChevronUp,
  faXmark,
  faMemo,
  faMegaphone,
  faChartSimple,
  faBookCopy,
  faSolarSystem,
  faGear,
  faFile,
  faHourglassStart,
  faSliders,
  faFileCircleCheck,
  faPlusCircle,
  faUpRightFromSquare,
  faUserGroup,
  faArrowLeft,
  faTimes,
  faWrench,
)
