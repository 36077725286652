import type { FiftyApiClientManager } from '@/store/services/apiClient/FiftyApiClientManager'
import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import { Challenge } from '@/store/FiftyTypes'
import type { IRetrieveSessionsPayload } from '@/store/modules/ChallengeSession'
import { DISPATCH_LOAD_SESSIONS_DATA } from '@/store/modules/ChallengeSession'
import type { IRefreshPayload } from '@/store/modules/Login'
import { COMMIT_INCREMENT_ACCEPTATION_STAT, DISPATCH_INCREMENT_VALIDATION_STAT } from '@/store/modules/Login'
import type { SegmentHelper } from '@/store/services/SegmentHelper'
import type { Router } from 'vue-router'
import type { DispatchRawPayload } from '@/store/modules/BaseStore'

export interface IChallengesState {
  challenges: Challenge[] | null
}

export interface ISessionCommit {
  challengeId: string
  userId: string
}

export interface ScheduleRawPayload {
  plannedExecutionTime: Date | null
  phoneNumber: string
  nextCalendarInviteAt: Date | null
  reminderAt: Date | null
  reminderChannelType: FiftyApiClients.ReminderChannelTypeDto | null
}

export interface IAcceptSessionCommit extends ISessionCommit, ScheduleRawPayload {
  challengeRefreshed?: Challenge | null | undefined
  needChallengeRefresh: boolean
}

export interface IAcceptChallengePayload extends DispatchRawPayload, IAcceptSessionCommit {
  isOb: boolean
  shortChallengeId?: string
}

export interface ILightAcceptChallengePayload extends DispatchRawPayload, IAcceptSessionCommit {
  shortChallengeId: string
}

export interface IPostAcceptationPayload {
  acceptChallengePayload: IAcceptChallengePayload | null
  isFirstAcceptableChallenge: boolean
  hasNoAcceptedChallenge: boolean
  isGivenAsChoice: boolean
  hasCompletedCourse: boolean
  isPostCustomChallengeCreation: boolean | null
}

export interface IValidateChallengePayload extends DispatchRawPayload {
  shortChallengeId: string
  router: Router
  userId: string
  onBackEndChallengeValidated: (shortChallengeId: string, router: Router) => void
}

export interface ICloseChallengeCommit extends ISessionCommit {
  doneStatus: FiftyApiClients.ChallengeDoneStatusDto
}

export interface ICloseChallengeDispatchPayload extends ICloseChallengeCommit, DispatchRawPayload {
  answer?: string | null
}

export interface ICreateAcceptAndRescheduleChallengePayload
  extends ScheduleRawPayload,
    DispatchRawPayload,
    IAcceptSessionCommit {}

export interface ICreateTeamChallenge {
  challengeId: string
  teamId: string
  apiClients: FiftyApiClientManager
}

export interface ICommitCreateTeamChallenge {
  challengeId: string
  newTeamUserChallenge: FiftyApiClients.GroupLightDto
}

export interface IRescheduleChallengePayload extends IAcceptSessionCommit {
  plannedExecutionTime: Date
  nextCalendarInviteAt: Date | null
  reminderAt: Date | null
  reminderChannelType: FiftyApiClients.ReminderChannelTypeDto | null
  phoneNumber: string
  apiClients: FiftyApiClientManager
  segment: SegmentHelper
}

export interface IGiveFeedbackPayload extends DispatchRawPayload {
  request: FiftyApiClients.GiveFeedbackRequest
  challengeId: string
}

export interface IValidateChallengeCommit {
  shortChallengeId: string
  updatedChallenge: FiftyApiClients.ChallengeDto
}

export const GET_CHALLENGE = 'ChallengesStore/getChallenge'

// sections
export const GET_ALL_COMPLETED_CHALLENGES_BY_PROGRAM_ID = 'ChallengesStore/getAllChallengesCompletedByProgramId'
export const GET_ALL_CUSTOM_CHALLENGES_BY_PROGRAM_ID = 'ChallengesStore/getAllCustomChallengesByProgramId'

export const GET_ALL_ON_GOING_CHALLENGES = 'ChallengesStore/getAllOnGoingChallenges'
export const GET_ALL_HAS_BEEN_ACCEPTED_AT_LEAST_ONCE_CHALLENGES =
  'ChallengesStore/getAllHasBeenAcceptedAtLeastOnceChallenges'
export const GET_CURRENT_SUGGESTED_CHALLENGES = 'ChallengesStore/getCurrentSuggestedChallenges'
export const GET_ALL_GROUP_CHALLENGES_COMPLETED = 'ChallengesStore/getAllGroupChallengesCompleted'

export const CLEAR_CHALLENGES = 'ChallengesStore/clearChallenges'
export const COMMIT_INNER_SAVE_CHALLENGES = 'ChallengesStore/saveChallenges'

// accept
export const COMMIT_CHALLENGE_ACCEPT = 'ChallengesStore/acceptChallenge'
export const DISPATCH_CHALLENGE_ACCEPT = 'ChallengesStore/acceptChallenge'
export const DISPATCH_CHALLENGE_ACCEPT_BY_SHORTID = 'ChallengesStore/acceptChallengeByShortId'
export const DISPATCH_POST_ANIMATION_CHALLENGE_ACCEPT = 'ChallengesStore/postAnimationAcceptChallenge'

// validate
export const INNER_COMMIT_CHALLENGE_VALIDATE = 'validateChallenge'
export const COMMIT_CHALLENGE_VALIDATE = 'ChallengesStore/validateChallengeExternal'
export const DISPATCH_CHALLENGE_VALIDATE = 'ChallengesStore/validateChallenge'

// habit
export const COMMIT_SET_HABIT = 'ChallengesStore/setAsHabit'
export const COMMIT_UNSET_HABIT = 'ChallengesStore/unsetHabit'

// reschedule
export const COMMIT_CHALLENGE_RESCHEDULE_INTERNAL_INTERNAL = 'rescheduleChallenge'
export const COMMIT_CHALLENGE_RESCHEDULE_INTERNAL = 'ChallengesStore/rescheduleChallenge'
export const DISPATCH_CHALLENGE_RESCHEDULE = 'ChallengesStore/scheduleChallenge'
export const DISPATCH_CREATE_ACCEPT_SCHEDULE_CHALLENGE = 'ChallengesStore/createAcceptAndScheduleChallenge'

// close
export const COMMIT_CLOSE_CHALLENGE_INTERNAL = 'closeChallenge'
export const DISPATCH_CLOSE_CHALLENGE = 'ChallengesStore/closeChallenge'

// save feedback
export const DISPATCH_GIVE_FEEDBACK = 'ChallengesStore/giveFeedback'
export const INNER_COMMIT_GIVE_FEEDBACK = 'saveChallengeFeedback'

// add challenge to store
export const ADD_CHALLENGE_TO_STORE = 'ChallengesStore/addChallengeToStore'

// team challenge
export const DISPATCH_CREATE_TEAM_CHALLENGE = 'ChallengesStore/createTeamChallenge'
export const COMMIT_NEW_TEAM_CHALLENGE = 'saveNewTeamChallenge' // usefull ?

export const DISPATCH_ACTION_ADDED_TO_GROUP = 'ChallengesStore/actionAddedToGroup'
const INNER_COMMIT_ACTION_ADDED_TO_GROUP = 'actionAddedToGroup'

export const MIN_ACCEPTATION_COUNT_TO_SET_AS_HABIT = 3

export interface IChallengesStore {
  namespaced: boolean
  state: IChallengesState
  mutations: any
  actions: any
  getters: any
}

export const ChallengesStore: IChallengesStore = {
  namespaced: true,
  state: {
    challenges: [],
  } as IChallengesState,
  mutations: {
    clearChallenges(state: IChallengesState) {
      state.challenges = null
    },
    saveChallenges(state: IChallengesState, payload: Challenge[] | null) {
      state.challenges = payload
    },
    addChallengeToStore(state: IChallengesState, payload: Challenge | null) {
      if (payload && state.challenges && !state.challenges.find((c) => c.programActionId === payload.programActionId)) {
        state.challenges.push(payload)
      }
    },
    acceptChallenge(state: IChallengesState, payload: IAcceptSessionCommit) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload.challengeId
      let challengeToAccept = state.challenges!.find(functionalCondition)!
      if (challengeToAccept) {
        if (payload.needChallengeRefresh) {
          // redo/retry
          challengeToAccept = payload.challengeRefreshed!
          return
        }
        const { usage, tags, data } = challengeToAccept
        if (!usage || !tags || !data) {
          return
        }
        usage.status = FiftyApiClients.ChallengeUsageStatusDto.Accepted
        usage.hasBeenAcceptedAtLeastOnce = true
        usage.plannedExecutionTime = payload.plannedExecutionTime

        usage.reminderData = new FiftyApiClients.ChallengeUsageReminderDataDto({
          nextReminderAt: payload.reminderAt,
          nextReminderType: payload.reminderChannelType,
          nextCalendarInviteAt: payload.nextCalendarInviteAt,
        })
      }
    },
    validateChallenge(state: IChallengesState, payload: IValidateChallengeCommit) {
      const functionalCondition = (c: Challenge) => c?.programAction.shortChallengeId === payload.shortChallengeId
      const challengeToValidate = state.challenges!.find(functionalCondition)!

      if (!challengeToValidate) {
        return
      }

      challengeToValidate.usage = payload.updatedChallenge.usage

      if (!payload.updatedChallenge.groups) {
        challengeToValidate.groups = []
      } else {
        challengeToValidate.groups = payload.updatedChallenge.groups
      }
    },
    validateChallengeExternal(state: IChallengesState, challengeId: string) {
      const functionalCondition = (c: Challenge) => c?.programActionId === challengeId
      const challengeToSetHasHabit = state.challenges!.find(functionalCondition)!
      if (!challengeToSetHasHabit) {
        return
      }
      const { usage } = challengeToSetHasHabit
      if (!usage) {
        return
      }
      usage.validatedCount++
    },
    setAsHabit(state: IChallengesState, payload: ISessionCommit) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload.challengeId
      const challengeToSetHasHabit = state.challenges!.find(functionalCondition)!
      if (!challengeToSetHasHabit) {
        return
      }
      const { usage } = challengeToSetHasHabit
      if (!usage) {
        return
      }
      usage.isAnActiveHabit = true
    },
    unsetHabit(state: IChallengesState, payload: string) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload
      const challengeToSetHasHabit = state.challenges!.find(functionalCondition)!
      if (!challengeToSetHasHabit) {
        return
      }
      const { usage } = challengeToSetHasHabit
      if (!usage) {
        return
      }
      usage.isAnActiveHabit = false
    },
    closeChallenge(state: IChallengesState, payload: ICloseChallengeCommit) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload.challengeId
      const challengeClosed = state.challenges!.find(functionalCondition)!
      if (!challengeClosed) {
        return
      }
      const { usage } = challengeClosed
      if (!usage) {
        return
      }
      usage.hasBeenCompletedAtLeastOnce = true
      usage.status = FiftyApiClients.ChallengeUsageStatusDto.Closed
      if (usage.validatedCount) {
        // challenge validated at least 1 time will never be closed again
        usage.status = FiftyApiClients.ChallengeUsageStatusDto.Validated
      }
    },
    rescheduleChallenge(state: IChallengesState, payload: IAcceptSessionCommit) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload.challengeId
      const challengeToReschedule = state.challenges!.find(functionalCondition)!
      if (!challengeToReschedule) {
        return
      }
      const { usage } = challengeToReschedule
      if (!usage) {
        return
      }
      usage.plannedExecutionTime = payload.plannedExecutionTime
      usage.reminderData!.nextReminderAt = payload.reminderAt
      usage.reminderData!.nextReminderType = payload.reminderChannelType
      usage.reminderData!.nextCalendarInviteAt = payload.nextCalendarInviteAt
    },
    saveChallengeFeedback(state: IChallengesState, payload: IGiveFeedbackPayload) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload.challengeId
      const challengeToFeed = state.challenges!.find(functionalCondition)!
      if (!challengeToFeed) {
        return
      }
      const { usage } = challengeToFeed
      if (!usage) {
        return
      }
      usage.rating = payload.request.rating
      usage.comment = payload.request.comment
    },
    saveNewTeamChallenge(state: IChallengesState, payload: ICommitCreateTeamChallenge) {
      const functionalCondition = (c: Challenge) => c?.programActionId === payload.challengeId
      const challengeToFeed = state.challenges!.find(functionalCondition)!

      if (!challengeToFeed.groups) {
        challengeToFeed.groups = [payload.newTeamUserChallenge]
      } else {
        challengeToFeed.groups.push(payload.newTeamUserChallenge)
      }
    },
    actionAddedToGroup(
      state: IChallengesState,
      payload: { groupLightDto: FiftyApiClients.GroupLightDto; challengeId: string },
    ) {
      const challengeToFeed = state.challenges?.find((c) => c.usage?.shortChallengeId === payload.challengeId)

      if (!challengeToFeed) {
        return
      }
      if (!challengeToFeed.groups) {
        challengeToFeed.groups = [payload.groupLightDto]
      } else {
        challengeToFeed.groups.push(payload.groupLightDto)
      }
    },
  },
  actions: {
    async createAcceptAndScheduleChallenge(
      { state, commit, dispatch }: any,
      payload: ICreateAcceptAndRescheduleChallengePayload,
    ): Promise<boolean | Challenge | null | undefined> {
      const acceptOnlyRequest = {
        challengeId: payload.challengeId,
        userId: payload.userId,
      } as FiftyApiClients.CreateAndAcceptChallengeRequest

      const res = await payload.apiClients.challengeApiClient.createAndAccept(acceptOnlyRequest)
      payload.challengeRefreshed = new Challenge(res.result!, res.result!.programActions![0])

      await dispatch(DISPATCH_POST_ANIMATION_CHALLENGE_ACCEPT, payload, { root: true })

      await dispatch(DISPATCH_CHALLENGE_RESCHEDULE, payload, { root: true })

      return true
    },
    async acceptChallengeByShortId(
      { state, commit, dispatch }: any,
      payload: ILightAcceptChallengePayload,
    ): Promise<boolean | Challenge | null | undefined> {
      await payload.apiClients.challengeApiClient.acceptFromShortChallengeId(payload.shortChallengeId)

      await dispatch(DISPATCH_POST_ANIMATION_CHALLENGE_ACCEPT, payload, { root: true })

      return true
    },
    async acceptChallenge({ state, commit, dispatch }: any, payload: IAcceptChallengePayload) {
      if (payload.needChallengeRefresh) {
        const acceptOnlyRequest = {
          challengeId: payload.challengeId,
          userId: payload.userId,
        } as FiftyApiClients.CreateAndAcceptChallengeRequest

        const res = await payload.apiClients.challengeApiClient.createAndAccept(acceptOnlyRequest)
        payload.challengeRefreshed = new Challenge(res.result!, res.result!.programActions![0])
      } else if (payload.isOb) {
        const scheduleRequest = {
          plannedExecutionTime: payload.plannedExecutionTime,
          phoneNumber: payload.phoneNumber,
          nextCalendarInviteAt: payload.nextCalendarInviteAt,
          nextReminderType: payload.reminderChannelType,
          nextReminderAt: payload.reminderAt,
        } as FiftyApiClients.ScheduleChallengeRequest

        if (payload.shortChallengeId) {
          await payload.apiClients.mainUserOnBoardingApiClient.acceptChallenge(
            payload.shortChallengeId,
            scheduleRequest,
          )
        }
      } else {
        await payload.apiClients.challengeApiClient.accept(payload.challengeId)
      }
    },

    async postAnimationAcceptChallenge({ commit, dispatch }: any, payload: IAcceptChallengePayload) {
      commit(COMMIT_CHALLENGE_ACCEPT, payload, { root: true })
      commit(COMMIT_INCREMENT_ACCEPTATION_STAT, null, { root: true })

      const payloadRefresh = {
        inBackgroundOnly: true,
        apiClients: payload.apiClients,
      } as IRetrieveSessionsPayload
      await dispatch(DISPATCH_LOAD_SESSIONS_DATA, payloadRefresh, { root: true })
    },
    async validateChallenge(
      { commit, dispatch }: any,
      payload: IValidateChallengePayload,
    ): Promise<FiftyApiClients.ValidateResponse | null | undefined> {
      const res = await payload.apiClients.challengeApiClient.validate(payload.shortChallengeId)
      if (res === null) {
        return null
      }

      await payload.onBackEndChallengeValidated(payload.shortChallengeId, payload.router)

      const validateChallengePayload = {
        shortChallengeId: payload.shortChallengeId,
        updatedChallenge: res.result?.updatedUserChallenge,
      } as IValidateChallengeCommit
      commit(INNER_COMMIT_CHALLENGE_VALIDATE, validateChallengePayload)

      const refreshPayload = {
        apiClients: payload.apiClients,
        segment: payload.segment,
        currentProgression: res.result?.currentProgression,
      } as IRefreshPayload
      dispatch(DISPATCH_INCREMENT_VALIDATION_STAT, refreshPayload, { root: true })

      return res?.result
    },
    async closeChallenge({ commit }: any, payload: ICloseChallengeDispatchPayload): Promise<boolean> {
      const request = { answer: payload.answer, doneStatus: payload.doneStatus } as FiftyApiClients.EndChallengeRequest
      const res = await payload.apiClients.challengeApiClient.endNotCompleted(payload.challengeId, request)
      if (res === null || !res.result || !res.result.isSuccessful) {
        return false
      }
      commit(COMMIT_CLOSE_CHALLENGE_INTERNAL, payload)
      return true
    },
    async scheduleChallenge({ commit }: any, payload: IRescheduleChallengePayload): Promise<boolean> {
      const request = {
        plannedExecutionTime: payload.plannedExecutionTime,
        nextReminderAt: payload.reminderAt,
        nextReminderType: payload.reminderChannelType,
        phoneNumber: payload.phoneNumber,
        nextCalendarInviteAt: payload.nextCalendarInviteAt,
      } as FiftyApiClients.ScheduleChallengeRequest
      const res = await payload.apiClients.challengeApiClient.schedule(payload.challengeId, request)
      if (res === null || !res.result || !res.result.isSuccessful) {
        return false
      }
      commit(COMMIT_CHALLENGE_RESCHEDULE_INTERNAL_INTERNAL, payload)
      return true
    },
    async giveFeedback({ commit }: any, payload: IGiveFeedbackPayload): Promise<boolean> {
      const res = await payload.apiClients.challengeApiClient.giveFeedback(payload.challengeId, payload.request)
      if (res === null || !res.result || !res.result.isSuccessful) {
        return false
      }

      commit(INNER_COMMIT_GIVE_FEEDBACK, payload)
      return true
    },
    async createTeamChallenge({ commit }: any, payload: ICreateTeamChallenge): Promise<boolean> {
      const res = await payload.apiClients.groupActionApiClient.createGroupAction(payload.teamId, payload.challengeId)
      if (res === null || !res.result) {
        return false
      }

      const commitRequest: ICommitCreateTeamChallenge = {
        challengeId: payload.challengeId,
        newTeamUserChallenge: res.result,
      }
      commit(COMMIT_NEW_TEAM_CHALLENGE, commitRequest)
      return true
    },
    actionAddedToGroup(
      { commit }: any,
      { groupLightDto, challengeId }: { groupLightDto: FiftyApiClients.GroupLightDto; challengeId: string },
    ) {
      commit(INNER_COMMIT_ACTION_ADDED_TO_GROUP, { groupLightDto, challengeId })
      return true
    },
  },
  getters: {
    getChallenge: (state: IChallengesState) => (id: string) =>
      state.challenges?.find(
        (challenge: Challenge) =>
          challenge.programActionId === id ||
          challenge.programAction.shortChallengeId === id ||
          challenge.usage?.lastUserChallengeId === id, // @deprecated to be removed when obsolete route is no more used
      ),
    getAllChallengesCompletedByProgramId: (state: IChallengesState) => (programId: string) =>
      state.challenges?.filter(
        (challenge: Challenge) =>
          challenge?.programAction.programId === programId &&
          challenge?.usage?.hasBeenCompletedAtLeastOnce &&
          !challenge?.usage?.isAnActiveHabit,
      ),
    getAllCustomChallengesByProgramId: (state: IChallengesState) => (programId: string) =>
      state.challenges?.filter(
        (challenge: Challenge) => challenge.programAction.programId === programId && challenge.data.isCustomChallenge,
      ),
    getAllOnGoingChallenges(state: IChallengesState): Challenge[] {
      return (
        state.challenges?.filter((c) => c?.usage?.status === FiftyApiClients.ChallengeUsageStatusDto.Accepted) || []
      )
    },
    getAllHasBeenAcceptedAtLeastOnceChallenges: (state: IChallengesState) => (programId: string) =>
      state.challenges?.filter(
        (v: Challenge) => v?.programAction.programId === programId && v.usage?.hasBeenAcceptedAtLeastOnce,
      ),
    getCurrentSuggestedChallenges: (state: IChallengesState) =>
      state.challenges?.filter((v: Challenge) => v.usage?.choiceUsage),
    getAllGroupChallengesCompleted: (state: IChallengesState) => () =>
      state.challenges?.filter((c) => c?.usage?.hasBeenCompletedAtLeastOnce && c?.groups?.length),
  },
}
