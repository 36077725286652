import type { LocationAsRelativeRaw, RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router'
import {
  PAGE_ACCEPT_GROUP_ACTION,
  PAGE_ADMIN_HOME,
  PAGE_ON_BOARDING_ACCEPT_CHALLENGE,
  PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION,
  PAGE_ON_BOARDING_CHALLENGE_CHOICE,
  PAGE_ON_BOARDING_CONGRATULATIONS,
  PAGE_ON_BOARDING_COURSE_DESCRIPTION,
  PAGE_ON_BOARDING_HOW_TO,
  PAGE_ON_BOARDING_INTRODUCTION,
  PAGE_ON_BOARDING_LEARNING_METHOD,
  PAGE_ON_BOARDING_PILLAR_SELECT,
  PAGE_ON_BOARDING_SEGMENTS,
  PAGE_SESSIONS_ON_GOING_CHALLENGES,
  PAGE_USER_CAMPAIGN_DONE,
  PAGE_USER_NO_PROGRAM,
  PAGE_USER_WITH_TEAMS_SIGNUP,
  PAGE_VALIDATE_GROUP_ACTION,
} from '@/router/routes'
import useUser from '@/hooks/useUser'
import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import { isAdminRoute } from '@/router/guards/AdminGuard'
import { isBackofficeRoute } from '@/router/guards/BackofficeGuard'
import store from '@/store/store'
import LoginManager, { USER_LOGGED_WITH_TEAMS_KEY } from '@/store/services/LoginManager'
import { StringHelper } from '@/store/services/UtilsService'
import { OnBoardingHelper } from '@/components/onBoarding/OnBoardingHelper'
import { DISPATCH_REFRESH_OB_DATA, GET_ON_BOARDING_DATA } from '@/store/modules/OnBoardingStore'
import { $apiClients } from '@/globalProperties'

function getCampaignCode(routeToAccess: RouteLocationNormalized): string | null {
  if (routeToAccess.query.code) {
    return routeToAccess.query.code as string
  }
  return localStorage.getItem(OnBoardingHelper.campaignCodeKey)
}

function hasAtLeastOneUserCampaign(user: FiftyApiClients.UserDto): boolean {
  if (!user.userCampaigns || user.userCampaigns.length === 0) {
    return false
  }

  return true
}

function isIntoOnBoardingChallengeChoice(routeToAccess: RouteLocationNormalized): boolean {
  return (
    routeToAccess.name === PAGE_ON_BOARDING_CHALLENGE_CHOICE || routeToAccess.name === PAGE_ON_BOARDING_ACCEPT_CHALLENGE
  )
}

function rerouteFromOnBoardingChallengeChoice(targetRoute: LocationAsRelativeRaw): boolean {
  if (!targetRoute) {
    return false
  }
  return targetRoute.name !== PAGE_ON_BOARDING_CHALLENGE_CHOICE
}

function getMainUserOnBoardingPageToGo(
  onBoardingStatus: FiftyApiClients.OnBoardingStatusDto,
  isAccountOfficiallyCreated: boolean,
  canRedirectIfAccountNotOfficiallyCreated: boolean,
): RouteLocationNamedRaw | null {
  const onBoardingData = store.getters[GET_ON_BOARDING_DATA]
  const stepSummary = onBoardingData?.stepSummary

  switch (onBoardingStatus) {
    case FiftyApiClients.OnBoardingStatusDto.NotStarted:
      if (isAccountOfficiallyCreated) {
        return { name: PAGE_ON_BOARDING_COURSE_DESCRIPTION }
      } else if (canRedirectIfAccountNotOfficiallyCreated) {
        return { name: PAGE_ON_BOARDING_INTRODUCTION }
      }
      return null

    // Go to step 2 -> Learning method
    case FiftyApiClients.OnBoardingStatusDto.HasSeenProgramPage:
      return { name: PAGE_ON_BOARDING_LEARNING_METHOD }

    // Go to step 3 -> How to
    case FiftyApiClients.OnBoardingStatusDto.HasSeenLearningMethodPage:
      return { name: PAGE_ON_BOARDING_HOW_TO }

    case FiftyApiClients.OnBoardingStatusDto.HasSeenOldProgramPage:
    case FiftyApiClients.OnBoardingStatusDto.HasSelectedProgram:
      return { name: PAGE_ON_BOARDING_LEARNING_METHOD }
    case FiftyApiClients.OnBoardingStatusDto.HasSeenHowToPage:
    case FiftyApiClients.OnBoardingStatusDto.HasSeenProgramSummaryPage:
      if (stepSummary?.shouldSkipSegments) {
        return { name: PAGE_ON_BOARDING_PILLAR_SELECT }
      }
      return { name: PAGE_ON_BOARDING_SEGMENTS }

    case FiftyApiClients.OnBoardingStatusDto.HasSeenCustomizationPage:
      return { name: PAGE_ON_BOARDING_PILLAR_SELECT }
    case FiftyApiClients.OnBoardingStatusDto.HasSeenSegmentsPage:
      return { name: PAGE_ON_BOARDING_PILLAR_SELECT }
    case FiftyApiClients.OnBoardingStatusDto.IsAtChallengeChoice:
    case FiftyApiClients.OnBoardingStatusDto.HasSeenPillarsPage:
      return { name: PAGE_ON_BOARDING_CHALLENGE_CHOICE }
  }
  return null
}

export const isEmployeeRoute = (route: RouteLocationNormalized) =>
  !['isAdmin', 'isBackOffice'].some((key) => key in route.meta)

export default class EmployeeGuard {
  private static getMainUserOnBoardingByCodeRedirection(
    routeToAccess: RouteLocationNormalized,
    user: FiftyApiClients.UserDto | null,
  ): RouteLocationNamedRaw | null {
    const isAuth: boolean = LoginManager.isLoggedIn()
    const campaignCode = getCampaignCode(routeToAccess)

    if (
      !isAuth ||
      !user ||
      !user.isAccountOfficiallyCreated ||
      [PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION, PAGE_ON_BOARDING_INTRODUCTION].includes(
        routeToAccess.name?.toString() || '',
      ) ||
      StringHelper.isNullOrEmpty(campaignCode)
    ) {
      return null
    }

    return {
      name: PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION,
      query: { code: campaignCode },
    }
  }

  private static getUserWithoutCourse(
    from: RouteLocationNormalized | null,
    to: RouteLocationNormalized,
    user: FiftyApiClients.UserDto | null,
  ): RouteLocationNamedRaw | null {
    if (!LoginManager.isLoggedIn() || !user || hasAtLeastOneUserCampaign(user)) {
      return null
    }

    // User should be able to access first page of on boarding to officially create its account and get the program
    if ([PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION, PAGE_ON_BOARDING_INTRODUCTION].includes(to.name?.toString() || ''))
      return null

    // then the user has no course and should be redirected
    // if it is actually an admin, let's move him back to the admin, otherwise, to the no program page
    if (!to.meta.isAdmin && user.userRights?.canAccessAdmin) {
      return { name: PAGE_ADMIN_HOME }
    }

    if (to?.name !== PAGE_USER_NO_PROGRAM) {
      return { name: PAGE_USER_NO_PROGRAM }
    }

    return null
  }

  private static getUserWithoutAllFinishedCoursesAndNotOnboarded(
    from: RouteLocationNormalized | null,
    to: RouteLocationNormalized,
    user: FiftyApiClients.UserDto | null,
  ): RouteLocationNamedRaw | null {
    if (!LoginManager.isLoggedIn() || !user || !user.hasNeverFinishedOnboarding) {
      return null
    }

    // User should be able to access first page of on boarding to officially create its account and get the program
    if ([PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION, PAGE_ON_BOARDING_INTRODUCTION].includes(to.name?.toString() || ''))
      return null

    if (!to.meta.isAdmin && user.userRights?.canAccessAdmin) {
      return { name: PAGE_ADMIN_HOME }
    }

    if (to?.name !== PAGE_USER_CAMPAIGN_DONE) {
      return { name: PAGE_USER_CAMPAIGN_DONE }
    }

    return null
  }

  private static async getMainUserOnBoardingRedirection(
    from: RouteLocationNormalized | null,
    routeToAccess: RouteLocationNormalized,
    user: FiftyApiClients.UserDto | null,
  ): Promise<RouteLocationNamedRaw | null> {
    const isAuth: boolean = LoginManager.isLoggedIn()
    if (
      !isAuth ||
      !user ||
      user.isClientContractExpired ||
      [PAGE_ON_BOARDING_CONGRATULATIONS, PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION].includes(
        routeToAccess.name?.toString() || '',
      )
    ) {
      return null
    }

    if (
      routeToAccess.name === PAGE_ON_BOARDING_INTRODUCTION &&
      (routeToAccess?.query.program || routeToAccess?.query.code)
    ) {
      return null
    }

    const isOnBoardingPage = !!routeToAccess.meta && !!routeToAccess.meta.isObStep

    if (isOnBoardingPage) {
      await store.dispatch(DISPATCH_REFRESH_OB_DATA, {
        apiClients: $apiClients,
        userMail: user.emailAddress,
        forceRefresh: true,
      })

      if (!user.isAccountOfficiallyCreated && routeToAccess.name === PAGE_ON_BOARDING_INTRODUCTION) {
        return null
      }

      // while logged in, if no current userCampaign, then nothing to on board
      if (!user.currentUserCampaign) {
        return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
      }

      if (user.currentUserCampaign && user.currentUserCampaign.hasFinishedOnBoarding) {
        return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
      }

      if (isIntoOnBoardingChallengeChoice(routeToAccess)) {
        const route = getMainUserOnBoardingPageToGo(
          user.currentUserCampaign.onBoardingStatus,
          user.isAccountOfficiallyCreated,
          !(routeToAccess.name === PAGE_ACCEPT_GROUP_ACTION || routeToAccess.name === PAGE_VALIDATE_GROUP_ACTION),
        )

        return route && rerouteFromOnBoardingChallengeChoice(route) ? route : null
      }

      if (!user.isAccountOfficiallyCreated && routeToAccess.name !== PAGE_ON_BOARDING_INTRODUCTION) {
        return { name: PAGE_ON_BOARDING_INTRODUCTION }
      }
    }

    if (!user.currentUserCampaign || user.currentUserCampaign.hasFinishedOnBoarding) {
      return null
    }

    const redirection = getMainUserOnBoardingPageToGo(
      user.currentUserCampaign.onBoardingStatus,
      user.isAccountOfficiallyCreated,
      !(routeToAccess.name === PAGE_ACCEPT_GROUP_ACTION || routeToAccess.name === PAGE_VALIDATE_GROUP_ACTION),
    )

    if (redirection && redirection.name !== routeToAccess.name) {
      return redirection
    }

    return null
  }

  private static getUserWithTeamsRedirection(
    from: RouteLocationNormalized | null,
    to: RouteLocationNormalized,
    user: FiftyApiClients.UserDto | null,
  ): RouteLocationNamedRaw | null {
    if (!user) {
      return null
    }

    const isComingFromTeams = localStorage.getItem(USER_LOGGED_WITH_TEAMS_KEY)

    // No need to go to the signup page if the user is already logged in
    if (isComingFromTeams && to.name === PAGE_USER_WITH_TEAMS_SIGNUP) {
      return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
    }

    return null
  }

  public static async redirect(to: RouteLocationNormalized, from: RouteLocationNormalized | null) {
    const { currentUser } = useUser()
    if (isAdminRoute(to) || isBackofficeRoute(to)) {
      return null
    }

    const redirection =
      this.getMainUserOnBoardingByCodeRedirection(to, currentUser.value) ||
      (await this.getMainUserOnBoardingRedirection(from, to, currentUser.value)) ||
      this.getUserWithoutCourse(from, to, currentUser.value) ||
      this.getUserWithoutAllFinishedCoursesAndNotOnboarded(from, to, currentUser.value) ||
      this.getUserWithTeamsRedirection(from, to, currentUser.value)

    if (redirection && redirection.name !== to.name) {
      return redirection
    }

    return null
  }
}
